<template>
  <div class="otchet__student__wrapper">
    <h3>Отчет учащихся</h3>
    <form @submit.prevent="func_get_data">
      <div class="form__group">
        <label>Школа</label>
        <v-select
          label="school"
          :options="school_list"
          v-model="school_value"
          @option:selecting="func_get_class_list"
        />
      </div>
      <div class="form__group">
        <label>Класс</label>
        <v-select label="class" :options="class_list" v-model="class_value" />
      </div>
      <button class="send__btn">Сформировать отчет</button>
    </form>
    <div class="table__otchet__student">
      <table v-if="otchet_list.length > 0">
        <thead>
          <tr>
            <th>№ПП</th>
            <th>Город</th>
            <th>Школа</th>
            <th>Лиц счет</th>
            <th>Признак льготы</th>
            <th>Класс</th>
            <th>ФИО ученика</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in otchet_list" :key="item.student_id">
            <td>{{ index + 1 }}</td>
            <td>{{ item.city }}</td>
            <td>{{ item.school }}</td>
            <td>{{ item.num_ls }}</td>
            <td>
              {{
                item.lgota_id
                  ? item.date_end
                    ? "Льгота " +
                      (item.type_lgota ? `(${item.type_lgota})` : "") +
                      " " +
                      new Date(item.date_end).toLocaleDateString()
                    : "Льгота " +
                      (item.type_lgota ? `(${item.type_lgota})` : "")
                  : ""
              }}
            </td>
            <td>{{ item.class }}</td>
            <td>{{ item.fio }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      school_value: "",
      class_value: "",
      school_list: [],
      class_list: [],
      otchet_list: [],
    };
  },
  methods: {
    func_get_school_list() {
      window.sendRequestAuth("/get_school_list", {}).then((result) => {
        if (!result) return;
        this.school_list = result;
      });
    },
    func_get_data() {
      if (this.school_value) {
        window
          .sendRequestAuth("/get_otchet_student", {
            school_id: this.school_value.school_id,
            class_id: this.class_value ? this.class_value.class_id : "",
          })
          .then((result) => {
            if (!result) return;
            this.otchet_list = result;
          });
      } else {
        window.soundClick();
        this.$notify({
          group: "foo",
          title: "Ошибка",
          text: "Заполните поле 'Школа'",
          type: "error",
        });
      }
    },
    func_get_class_list(item) {
      this.school_value = item;
      window
        .sendRequestAuth("/get_class_list", {
          school_id: this.school_value.school_id,
        })
        .then((result) => {
          if (!result) return;
          this.class_list = result;
        });
    },
  },
  mounted() {
    this.func_get_school_list();
  },
};
</script>

<style></style>
