<template>
  <div class="control__order__food__wrapper">
    <h3>Отчет по льготам</h3>
    <form @submit.prevent="func_get_data">
      <div class="form__group">
        <label>Школа</label>
        <v-select label="school" :options="school_list" v-model="school_value" />
      </div>
      <div class="form__group">
        <label>Дата</label>
        <div class="date__flex">
          <input type="date" required v-model="date_start" />
          <span>-</span>
          <input type="date" required v-model="date_end" />
        </div>
      </div>
      <button class="send">Сформировать отчет</button>
    </form>
    <div class="table__result" v-if="order_food.length > 0">
      <table class="order__food" border="1">
        <thead>
          <tr>
            <th>Категории льгот:</th>
            <th v-for="item of order_food" :key="item">
              {{ item.type_lgota }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="color: #000; text-align: center">Итого:</td>
            <td v-for="item of order_food" :key="item" style="text-align: center">
              {{ item.value }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table__clear" v-if="order_food.length === 0 && progress === false">
      Ничего не найдено
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      school_list: [],
      school_value: null,
      date_start: "",
      date_end: "",
      order_food: [],
      progress: true,
    };
  },
  methods: {
    func_clear_order() {
      this.order_food = [];
      this.progress = true;
    },
    func_get_school_list() {
      window.sendRequestAuth("/get_school_list", {}).then((result) => {
        if (!result) return;
        this.school_list = result;
      });
    },

    func_get_data() {
      if (!this.school_value) {
        window.soundClick();
        this.$notify({
          group: "foo",
          title: "Ошибка",
          text: "Выберите школу",
          type: "error",
        });
        return;
      }
      this.progress = true;
      window
        .sendRequestAuth("/get_otchet_type_lgota", {
          school_id: this.school_value.school_id,
          date_start: this.date_start,
          date_end: this.date_end,
        })
        .then((result) => {
          this.order_food = result.all || [];
          this.progress = false;
        });
    },
  },
  mounted() {
    this.func_get_school_list();
  },
};
</script>

<style></style>
