<template>
  <div class="general__img__wrapper">
    <a :href="link" v-if="link">
      <img :src="image_src" />
    </a>
    <img v-img v-else :src="image_src" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      progress: false,
      image_src: "",
      link: null,
    };
  },
  methods: {
    get_image() {
      window.sendRequest("/get_general_img", {}).then((result) => {
        if (result) {
          this.progress = false;
          this.image_src = "https://bpcard.ru/images/general_img/" + result.img;
          if (result.link) this.link = result.link;
        }
      });
    },
  },
  mounted() {
    this.get_image();
  },
};
</script>

<style></style>
