<template>
  <div>
    <div
      class="run__line"
      v-if="visible"
      :style="{
        background: background,
      }"
    >
      <div class="container">
        <div
          class="b-marquee"
          :style="{
            color: color,
            fontSize: (font_size || 12) + 'px',
          }"
        >
          <div
            class="b-marquee__text"
            :style="`animation: animMarqueeRtl ${speed}s linear infinite`"
          >
            {{ text }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      text: "",
      color: "",
      background: "",
      speed: "",
      font_size: "",
    };
  },
  methods: {
    func_get_data() {
      window.sendRequest("/get_run_line", {}).then((result) => {
        if (!result) return;
        this.visible = +result.visible === 1 ? true : false;
        this.text = result.text;
        this.color = result.color;
        this.background = result.background;
        this.speed = result.speed;
        this.font_size = result.font_size;
      });
    },
  },
  mounted() {
    this.func_get_data();
  },
};
</script>

<style>
.b-marquee {
  font-family: "Segoe UI", sans-serif;
  white-space: nowrap;
  overflow: hidden;
  padding: 3px;
  position: relative;
}

@-webkit-keyframes animMarqueeRtl {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-300%);
    transform: translateX(-300%);
  }
}

@keyframes animMarqueeRtl {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-300%);
    transform: translateX(-300%);
  }
}
</style>