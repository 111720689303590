<template>
  <div class="tabel__lgota__wrapper">
    <h3>Табель учета дней посещения детей льготной категории</h3>
    <div class="tabel__form">
      <form @submit.prevent="func_get_data">
        <div class="form__group">
          <label>Школа</label>
          <v-select label="school" :options="school_list" v-model="school_value" />
        </div>
        <div class="form__group">
          <label>Год</label>
          <v-select label="value" :options="years_list" v-model="year_value" />
        </div>
        <div class="form__group">
          <label>Месяц</label>
          <v-select label="value" :options="mounth_list" v-model="mounth_value" />
        </div>
        <button class="btn">Сформировать отчет</button>
      </form>
    </div>
    <div v-if="loader">Загрузка, подождите...</div>
    <div class="tabel__table" v-if="result_list.length">
      <button @click="func_export" class="btn__export">
        <i class="fa fa-file-excel-o" aria-hidden="true"></i>Экспорт в Excel
      </button>
      <table id="table_export">
        <thead>
          <tr>
            <th>№ПП</th>
            <th>ФИО</th>
            <th>Тип льготы</th>
            <th>Класс</th>
            <th v-for="n in func_get_count_days()" :key="n">{{ n }}</th>
            <th>Итого:</th>
            <th>Срок действия</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in result_list" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.fio }}</td>
            <td>{{ item.type_lgota }}</td>
            <td>{{ item.class }}</td>
            <td v-for="n in func_get_count_days()" :key="n">
              {{ item[n] ? "+" : "" }}
            </td>
            <td>{{ func_get_itogo(item) }}</td>
            <td>
              {{ item.date_end ? new Date(item.date_end).toLocaleDateString() : "" }}
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>Итого:</td>
            <td></td>
            <td v-for="n in func_get_count_days()" :key="n">
              {{ func_get_itogo_all(n) === 0 ? "" : func_get_itogo_all(n) }}
            </td>
            <td>{{ func_get_itog_school() }}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import XLSX from "xlsx";
export default {
  data() {
    return {
      result_list: [],
      school_list: [],
      school_value: null,
      mounth_value: null,
      year_value: new Date().getFullYear(),
      loader: false,
      years_list: [new Date().getFullYear() - 1, new Date().getFullYear()],
      mounth_list: [
        { id: 1, value: "Январь" },
        { id: 2, value: "Февраль" },
        { id: 3, value: "Март" },
        { id: 4, value: "Апрель" },
        { id: 5, value: "Май" },
        { id: 6, value: "Июнь" },
        { id: 7, value: "Июль" },
        { id: 8, value: "Август" },
        { id: 9, value: "Сентябрь" },
        { id: 10, value: "Октябрь" },
        { id: 11, value: "Ноябрь" },
        { id: 12, value: "Декабрь" },
      ],
    };
  },
  methods: {
    func_get_itog_school() {
      let count = 0;
      if (this.result_list.length > 0) {
        this.result_list.forEach((item) => {
          count += this.func_get_itogo(item);
        });
      }
      return count;
    },
    func_get_school_list() {
      window.sendRequestAuth("/get_school_list", {}).then((result) => {
        if (!result) return;
        this.school_list = result;
      });
    },
    func_get_itogo_all(n) {
      let count = 0;
      this.result_list.forEach((item) => {
        if (item[n]) count++;
      });
      return count;
    },
    func_get_data() {
      this.result_list = [];
      if (this.loader) return;
      this.loader = true;
      if (!this.school_value || !this.mounth_value) {
        window.soundClick();
        this.$notify({
          group: "foo",
          title: "Ошибка",
          text: "Заполните поля",
          type: "error",
        });
        return;
      }
      window
        .sendRequestAuth("/get_tabel_lgota", {
          mounth: this.mounth_value.id,
          year: this.year_value,
          school_id: this.school_value.school_id,
        })
        .then((result) => {
          if (!result) return;
          let students = result.fio;
          let full = result.full;

          let count_day = this.func_get_count_days();

          students.forEach((item) => {
            for (let i = 1; i <= count_day; i++) {
              let obj = full.find(
                (x) => x.num_ls === item.num_ls && new Date(x.dateOrder).getDate() === i
              );
              if (obj) item[i] = true;
              else item[i] = false;
            }
          });
          this.result_list = [...students].sort((a, b) => (a.class > b.class ? 1 : -1));
          this.loader = false;
        });
    },
    func_get_count_days() {
      if (!this.mounth_value) return;

      return new Date(
        new Date().getFullYear(),
        this.mounth_value.id.length === 1
          ? "0" + this.mounth_value.id
          : this.mounth_value.id,
        0
      ).getDate();
    },
    func_get_itogo(obj) {
      let count = 0;
      for (const val in obj) {
        if (!isNaN(Number(val))) {
          if (obj[val]) {
            count++;
          }
        }
      }
      return count;
    },
    func_export() {
      var data = XLSX.utils.table_to_sheet(document.querySelector("#table_export"), {
        raw: true,
      });
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data);
      XLSX.writeFile(wb, "Табель учета дней.xlsx");
    },
  },
  mounted() {
    this.func_get_school_list();
  },
};
</script>

<style></style>
