import Vue from 'vue'
import App from './App.vue'
import Notifications from "vue-notification";
import axios from 'axios';
import store from './store.js';
import router from './router/router';

import VueEditor from 'vue2-editor'
import Quill from 'vue2-editor'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import VueImg from 'v-img';
import JsonExcel from 'vue-json-excel'
import 'dotenv/config'

Vue.component('downloadExcel', JsonExcel)

const vueImgConfig = {
  // Consider alt of image as its title in gallery?
  altAsTitle: false,
}
Vue.use(VueImg, vueImgConfig);


Vue.config.productionTip = false
Vue.use(Notifications);

window.url = process.env.VUE_APP_REQUEST_URL + "backEnd/request.php";
window.sendRequest = function (url, param) {
  let fd = new FormData();
  for (const item in param) {
    fd.append(item, param[item]);
  }
  return new Promise(function (resolve, reject) {
    axios.post(window.url + url,
      fd, {
      headers: {
        auth: `${localStorage.getItem('api_key') ? localStorage.getItem('api_key') : null}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
    ).then((response) => {
      if (response) {
        resolve(response.data ? response.data : null);
      }
    }).catch(function (error) {
      reject({ status: 1, errorText: error });
    });
  });
}

window.url2 = process.env.VUE_APP_REQUEST_URL + "backEnd/request_auth.php";
window.sendRequestAuth = function (url, param) {
  let fd = new FormData();
  for (const item in param) {
    fd.append(item, param[item]);
  }
  return new Promise(function (resolve, reject) {
    axios.post(window.url2 + url,
      fd, {
      headers: {
        auth: `${localStorage.getItem('api_key') ? localStorage.getItem('api_key') : null}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
    ).then((response) => {
      if (response) {
        if (response.data.auth) {
          resolve(null);
        } else {
          resolve(response.data);
        }
      }
    }).catch(function (error) {
      reject({ status: 1, errorText: error });
    });
  });
}


window.soundClick = function () {
  var audio = new Audio(); // Создаём новый элемент Audi
  audio.src = 'file/click.mp3'; // Указываем путь к звуку "клика"
  audio.volume = 0.2; // Громкость
  audio.autoplay = true; // Автоматически запускаем
}

Vue.component('v-select', vSelect)

new Vue({
  render: h => h(App),
  store,
  router,
  VueEditor,
  Quill,
}).$mount('#app')
