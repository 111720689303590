<template>
  <div class="content">
    <Header />
    <HeaderInfo />
    <Menu @active_page="func_update_active_page" :active_page="active_page" />
    <div class="function__wrapper">
      <div class="container">
        <AddLgota v-if="Number(id) === 1" />
        <OtchetStudent v-if="Number(id) === 2" />
        <ControlOrderFood v-if="Number(id) === 3" />
        <OtchetOrders v-if="Number(id) === 4" />
        <OrderFood v-if="Number(id) === 5" />
        <TabeLgota v-if="Number(id) === 6" />
        <TypeLgota v-if="Number(id) === 7" />
        <tabelFreeFood v-if="Number(id) === 8" />
        <parentMoney v-if="Number(id) === 9" />
        <GosMoney v-if="Number(id) === 10" />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import HeaderInfo from "@/components/Header_info.vue";
import Menu from "@/components/Menu.vue";

import AddLgota from "@/components/Functions/addLgota.vue";
import OtchetStudent from "@/components/Functions/otchetStudent.vue";
import ControlOrderFood from "@/components/Functions/ControlOrderFood.vue";
import OtchetOrders from "@/components/Functions/otchetOrders.vue";
import OrderFood from "@/components/Functions/orderFood.vue";
import TabeLgota from "@/components/Functions/tabeLgota.vue";
import TypeLgota from "@/components/Functions/otchetLgota.vue";
import tabelFreeFood from "../Functions/tabelFreeFood.vue";
import parentMoney from "../Functions/ParentMoney.vue";
import GosMoney from "../Functions/GosMoney.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Header,
    HeaderInfo,
    Menu,
    AddLgota,
    OtchetStudent,
    ControlOrderFood,
    OtchetOrders,
    OrderFood,
    TabeLgota,
    TypeLgota,
    tabelFreeFood,
    parentMoney,
    GosMoney,
  },
  props: {
    id: String,
  },
  data() {
    return {
      active_page: 1,
    };
  },
  watch: {
    USER_STATE() {
      this.func_check_user();
    },
  },
  methods: {
    func_update_active_page(value) {
      if (this.$router.currentRoute.path !== "/") {
        this.$router.push({
          name: "main",
          params: {
            view_id: value,
          },
        });
      }
    },
    func_check_user() {
      if (this.$store.state.user) {
        if (Number(this.$store.state.user.type) === 0) {
          if (this.$store.state.user.teacher) {
            if (Number(this.id) !== 5) {
              this.$router.push("/");
            }
          } else {
            this.$router.push("/");
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters(["USER_STATE"]),
  },
  mounted() {
    this.func_check_user();
    this.active_page = 0;
  },
};
</script>

<style></style>
