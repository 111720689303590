<template>
  <div class="content">
    <Header />
    <HeaderInfo />
    <div class="pay__info__wrap">
      <img src="https://bpcard.ru//images/kalr.png" alt="" />
      <h4>Платеж успешно обработан</h4>
      <h3>Деньги зачисляются на счет в течении 20 минут</h3>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import HeaderInfo from "@/components/Header_info.vue";
export default {
  components: {
    Header,
    HeaderInfo,
    // Footer,
  },
};
</script>

<style></style>
