<template>
  <div class="project__wrapper">
    <h4 class="title">О проекте</h4>
    <div class="project__content">
      <p>
        Комбинат Здорового Питания «Большая Перемена» — новейшее, динамично развивающееся
        предприятие пищевой промышленности с растущей торговой сетью магазинов и торговых
        представительств по Поволжью. Основной задачей нашей компании стоит предоставление
        жителям современных городов качественных и полезных продуктов, безопасных,
        отвечающим самым строгим требованиям санитарных норм.
      </p>

      <p>
        Качество нашей продукции высоко оценено не только постоянными покупателями, но и
        администрацией нескольких городов, оказывающих нам доверие в поставках питания в
        образовательные учреждения Саратова, Балаково, Энгельса и других.
      </p>
      <p>
        Сегодня мы готовы предоставить вам широкий ассортимент позиций от мясного,
        хлебобулочного, овощного производства.
      </p>

      Главные преимущества продукции комбината здорового питания «Большая Перемена» это:

      <ul>
        <li>
          — тщательный контроль над поставщиками и полное соответствие Федеральному Закону
          №47 «О качестве и безопасности пищевых продуктов», такие требования
          предъявляются к поставщикам школьного питания, кем мы и являемся;
        </li>
        <li>
          — наличие собственной лаборатории для детального и объективного анализа
          поступающего сырья на производство комбината "Большая Перемена";
        </li>
        <li>— самое современное производственное оборудование;</li>
        <li>— минимальная наценка на готовую продукцию;</li>
        <li>
          — выверенная логистика поставок и снабжения, что позволяет предлагать для
          покупателей самый свежий и полезный продукт без изнурительной заморозки Будем
          рады видеть вас среди наших покупателей и партнеров!
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
