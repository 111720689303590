<template>
  <div class="tabel__lgota__wrapper">
    <h3>Табель бесплатного питания 1-4 классы (за месяц)</h3>
    <div class="tabel__form">
      <form @submit.prevent="func_get_data">
        <div class="form__group">
          <label>Школа</label>
          <v-select label="school" :options="school_list" v-model="school_value"
            @option:selecting="func_get_class_list" />
        </div>
        <div class="form__group">
          <label>Класс</label>
          <v-select label="class" :options="class_list" v-model="class_value" />
        </div>
        <div class="form__group">
          <label>Год</label>
          <v-select label="value" :options="years_list" v-model="year_value" />
        </div>
        <div class="form__group">
          <label>Месяц</label>
          <v-select label="value" :options="mounth_list" v-model="mounth_value" />
        </div>
        <button class="btn">Сформировать отчет</button>
      </form>
    </div>
    <div v-if="loader">Загрузка, подождите...</div>
    <div class="tabel__table" v-if="result_list.length || result_tabel_list.length">
      <div class="btn-group">
        <button @click="func_export" class="btn__export">
          <i class="fa fa-file-excel-o" aria-hidden="true"></i>Экспорт в Excel
        </button>
        <!-- <button @click="func_get_tabel_data" class="btn__export">
          <i class="fa fa-address-card-o" aria-hidden="true"></i>Сформировать отчет по ФИО
        </button> -->
      </div>
      <table id="table_export" v-if="!is_tabel">
        <thead>
          <tr>
            <th>Класс</th>
            <th>Кол-во по списку</th>
            <th v-for="n in func_get_count_days()" :key="n">{{ n }}</th>
            <th>Итого:</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in rows" :key="index">
            <td>{{ item.class }}</td>
            <td>{{ item.count_students }}</td>
            <td v-for="n in func_get_count_days()" :key="n">
              {{
                getCountForDay(n, item.class_id)
                  ? getCountForDay(n, item.class_id).count
                  : ""
              }}
            </td>
            <td>{{ func_get_itogo(item.class_id) }}</td>
          </tr>
          <tr>
            <td>Итого:</td>
            <td></td>
            <td v-for="n in func_get_count_days()" :key="n">
              {{ func_get_itogo_all(n) === 0 ? "" : func_get_itogo_all(n) }}
            </td>
            <td>{{ func_get_itog_school() }}</td>
          </tr>
        </tbody>
      </table>

      <table id="table_export" v-else>
        <thead>
          <tr>
            <th>№ПП</th>
            <th>ФИО</th>
            <th>Класс</th>
            <th v-for="n in func_get_count_days()" :key="n">{{ n }}</th>
            <th>Итого:</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in result_tabel_list" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.fio }}</td>
            <td>{{ item.class }}</td>
            <td v-for="n in func_get_count_days()" :key="n">
              {{ item[n] ? "+" : "" }}
            </td>
            <td>{{ func_get_itogo_tabel(item) }}</td>
          </tr>
          <tr>
            <td></td>
            <td>Итого:</td>
            <td></td>
            <td v-for="n in func_get_count_days()" :key="n">
              {{ func_get_itogo_all_tabel(n) === 0 ? "" : func_get_itogo_all_tabel(n) }}
            </td>
            <td>{{ func_get_itog_school_tabel() }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import XLSX from "xlsx";
export default {
  data() {
    return {
      result_list: [],
      result_tabel_list: [],
      school_list: [],
      class_list: [],
      rows: [],
      school_value: null,
      class_value: null,
      mounth_value: null,
      year_value: new Date().getFullYear(),
      years_list: [new Date().getFullYear() - 1, new Date().getFullYear()],
      loader: false,
      is_tabel: false,
      mounth_list: [
        { id: 1, value: "Январь" },
        { id: 2, value: "Февраль" },
        { id: 3, value: "Март" },
        { id: 4, value: "Апрель" },
        { id: 5, value: "Май" },
        { id: 6, value: "Июнь" },
        { id: 7, value: "Июль" },
        { id: 8, value: "Август" },
        { id: 9, value: "Сентябрь" },
        { id: 10, value: "Октябрь" },
        { id: 11, value: "Ноябрь" },
        { id: 12, value: "Декабрь" },
      ],
    };
  },
  methods: {
    func_get_class_list(item) {
      this.school_value = item;
      window
        .sendRequestAuth("/get_class_list", {
          school_id: this.school_value.school_id,
        })
        .then((result) => {
          if (!result) return;
          this.class_list = (result || []).filter(x => x.class.replace(/[^0-9]/g, '') <= 4);
        });
    },
    async func_get_tabel_data() {
      this.is_tabel = true;
      this.result_tabel_list = [];
      if (this.loader) return;
      this.loader = true;
      if (!this.school_value || !this.mounth_value) {
        window.soundClick();
        this.$notify({
          group: "foo",
          title: "Ошибка",
          text: "Заполните поля",
          type: "error",
        });
        return;
      }
      let result = await window
        .sendRequestAuth("/get_tabel_free", {
          mounth: this.mounth_value.id,
          year: this.year_value,
          school_id: this.school_value.school_id,
          class_id: this.class_value.class_id
        })

      if (!result) return;
      let students = result.fio;
      let full = result.full;

      let count_day = this.func_get_count_days();

      students.forEach((item) => {
        for (let i = 1; i <= count_day; i++) {
          let obj = full.find(
            (x) => x.num_ls === item.num_ls && new Date(x.dateOrder).getDate() === i
          );
          if (obj) item[i] = true;
          else item[i] = false;
        }
      });
      this.result_tabel_list = [...students].sort((a, b) => (a.fio > b.fio ? 1 : -1));
      this.loader = false;

    },
    getCountForDay(n, class_id) {
      return this.result_list.find((x) => x.class_id == class_id && x.day == n);
    },
    func_get_itog_school() {
      let count = 0;
      if (this.rows.length > 0) {
        this.rows.forEach((item) => {
          count += this.func_get_itogo(item.class_id);
        });
      }
      return count;
    },
    func_get_itog_school_tabel() {
      let count = 0;
      if (this.result_tabel_list.length > 0) {
        this.result_tabel_list.forEach((item) => {
          count += this.func_get_itogo_tabel(item);
        });
      }
      return count;
    },
    func_get_school_list() {
      window.sendRequestAuth("/get_school_list", {}).then((result) => {
        if (!result) return;
        this.school_list = result;
      });
    },
    func_get_itogo_all(n) {
      return this.result_list.reduce((accumulator, currentValue) => {
        if (currentValue.day == n) accumulator += +currentValue.count;
        return accumulator;
      }, 0);
    },
    func_get_itogo_all_tabel(n) {
      return this.result_tabel_list.reduce((accumulator, currentValue) => {
        if (currentValue[n]) accumulator++;
        return accumulator;
      }, 0);
    },
    async func_get_data() {
      if (this.class_value) {
        this.is_tabel = true;
        await this.func_get_tabel_data();
        return;
      }
      this.is_tabel = false;
      this.result_list = [];
      if (this.loader) return;
      this.loader = true;
      if (!this.school_value || !this.mounth_value) {
        window.soundClick();
        this.$notify({
          group: "foo",
          title: "Ошибка",
          text: "Заполните поля",
          type: "error",
        });
        return;
      }
      window
        .sendRequestAuth("/get_tabel_free_food", {
          mounth: this.mounth_value.id,
          year: this.year_value,
          school_id: this.school_value.school_id,
        })
        .then((result) => {
          if (!result) return;

          this.rows = result.reduce((accumulator, currentValue) => {
            if (!accumulator.find((x) => x.class_id == currentValue.class_id)) {
              accumulator.push(currentValue);
            }
            return accumulator;
          }, []);
          this.result_list = result;
          this.loader = false;
        });
    },
    func_get_count_days() {
      if (!this.mounth_value) return;

      return new Date(
        new Date().getFullYear(),
        this.mounth_value.id.length === 1
          ? "0" + this.mounth_value.id
          : this.mounth_value.id,
        0
      ).getDate();
    },
    func_get_itogo_tabel(obj) {
      let count = 0;
      for (const val in obj) {
        if (!isNaN(Number(val))) {
          if (obj[val]) {
            count++;
          }
        }
      }
      return count;
    },
    func_get_itogo(class_id) {
      return this.result_list.reduce((accumulator, currentValue) => {
        if (currentValue.class_id == class_id) accumulator += +currentValue.count;
        return accumulator;
      }, 0);
    },
    func_export() {
      var data = XLSX.utils.table_to_sheet(document.querySelector("#table_export"), {
        raw: true,
      });
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data);
      XLSX.writeFile(wb, "Табель бесплатного питания.xlsx");
    },
  },
  mounted() {
    this.func_get_school_list();
  },
};
</script>

<style>
.btn-group {
  display: flex;
}

.btn-group button {
  margin-right: 10px;
}
</style>
