<template>
  <div class="tabel__lgota__wrapper">
    <h3>Денежные средства родителей</h3>
    <div class="tabel__form">
      <form @submit.prevent="func_get_data">
        <div class="form__group">
          <label>Школа</label>
          <v-select label="school" v-model="school_value" :options="school_list" />
        </div>
        <div class="form__group">
          <label>Дата</label>
          <div class="date__flex">
            <input type="date" required v-model="dateStart" />
            <span>-</span>
            <input type="date" required v-model="dateEnd" />
          </div>
        </div>

        <button class="btn">Сформировать отчет</button>
      </form>
    </div>
    <div v-if="loader">Загрузка, подождите...</div>
    <div class="tabel__table" v-if="result_list">
      <p v-if="school_value && dateStart && dateEnd">
        {{ school_value?.school }}
        <span>{{
          new Date(dateStart).toLocaleDateString() +
          ' - ' +
          new Date(dateEnd).toLocaleDateString()
        }}</span>
      </p>
      <table border="1" class="table-parent_money">
        <tr>
          <th class="color-green">Поступление родительских денег</th>
          <th colspan="6" class="color-blue">Расход родительских денег</th>
        </tr>
        <tr>
          <th rowspan="3" class="color-green prix">{{ Number(result_list.prix || 0).toFixed(2) }}</th>
          <th class="color-1">Завтрак</th>
          <th class="color-2">Обед</th>
          <th class="color-3">Полдник</th>
          <th class="color-4">ГПД</th>
          <th class="color-5">Буфет</th>
          <th class="color-6">Доплата по льготному питанию</th>
        </tr>
        <tr>
          <td class="color-1">{{ Number(result_list.rasx.find(x => x.spr_type_cash_id == 29)?.summ || 0).toFixed(2) }}
          </td>
          <td class="color-2">{{ Number(result_list.rasx.find(x => x.spr_type_cash_id == 30)?.summ || 0).toFixed(2) }}
          </td>
          <td class="color-3">{{ Number(result_list.rasx.find(x => x.spr_type_cash_id == 31)?.summ || 0).toFixed(2) }}
          </td>
          <td class="color-4">{{ Number(result_list.rasx.find(x => x.spr_type_cash_id == 32)?.summ || 0).toFixed(2) }}
          </td>
          <td class="color-5">{{ Number(result_list.rasx.find(x => x.spr_type_cash_id == 1)?.summ || 0).toFixed(2) }}
          </td>
          <td class="color-6">{{ Number(result_list.rasx.reduce((acc, val) => {
            if (val.spr_type_cash_id == 17 || val.spr_type_cash_id == 20 || val.spr_type_cash_id == 26) {
              return acc + (val.summ ? Number(val.summ || 0) : 0);
            } else {
              return acc;
            }
          }, 0)).toFixed(2) }}</td>
        </tr>
        <tr>
          <td colspan="6" class="color-blue">Итого: {{ Number(result_list.rasx.reduce((acc, val) => {
            return acc + (val.summ ? Number(val.summ) : 0);
          }, 0)).toFixed(2) }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      result_list: null,
      school_list: [],
      rows: [],
      school_value: null,
      dateStart: '',
      dateEnd: '',
      loader: false,
    };
  },
  methods: {
    func_get_school_list() {
      window.sendRequestAuth('/get_school_list', {}).then(result => {
        if (!result) return;
        this.school_list = result;
      });
    },
    func_get_data() {
      this.result_list = null;
      if (this.loader) return;
      this.loader = true;
      if (!this.school_value) {
        window.soundClick();
        this.$notify({
          group: 'foo',
          title: 'Ошибка',
          text: 'Заполните поля',
          type: 'error',
        });
        return;
      }
      window
        .sendRequestAuth('/get_parent_money', {
          school_id: this.school_value.school_id,
          date_start: this.dateStart,
          date_end: this.dateEnd,
        })
        .then(result => {
          if (!result) return;
          this.result_list = result;
          this.loader = false;
        });
    },
  },
  mounted() {
    this.func_get_school_list();
  },
};
</script>

<style>
.date__flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.date__flex input {
  flex-basis: 48%;
  width: 100%;
  display: block;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: none;
}

.table-parent_money {
  font-size: 16px;
}

.table-parent_money th {
  padding: 10px;
}


.table-parent_money .color-blue {
  background: #43b8ff;
}

.table-parent_money .color-green {
  background: #86c148;
}

.table-parent_money .color-1 {
  background: #aacbe9;
}

.table-parent_money .prix {
  font-size: 16px;
  padding: 10px;
}

.table-parent_money .color-2 {
  background: #d5b977;
}

.table-parent_money .color-3 {
  background: #df7aff;
}

.table-parent_money .color-4 {
  background: #b8c8f7;
}

.table-parent_money .color-5 {
  background: #fffdb4;
}

.table-parent_money .color-6 {
  background: #dcffdc;
}

.table-parent_money th,
.table-parent_money td {
  font-size: 14px;
  text-align: center;
}

.table-parent_money td {
  font-size: 16px;
  padding: 10px;
}
</style>
