<template>
  <div class="new__wrapper">
    <Header />
    <HeaderInfo />
    <Menu @active_page="func_update_active_page" :active_page="active_page" />
    <div class="new__content" v-if="new_item">
      <div class="new__header">
        <div class="new__img">
          <img
            v-img
            :src="'https://bpcard.ru/images/news/' + new_item.img"
            :alt="new_item.title"
          />
        </div>
        <div class="new__title">
          <h2>{{ new_item.title }}</h2>
          <p>
            {{
              new Date(new_item.create_date).toLocaleDateString() +
              " " +
              new Date(new_item.create_date).toLocaleTimeString()
            }}
          </p>
        </div>
      </div>
      <div class="new__body" v-html="new_item.text"></div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import HeaderInfo from "@/components/Header_info.vue";
import Menu from "@/components/Menu.vue";
export default {
  props: {
    id: String,
  },
  components: {
    Header,
    HeaderInfo,
    Menu,
  },
  data: () => ({
    new_item: null,
    active_page: 1,
  }),
  methods: {
    func_get_data() {
      window.sendRequest("/get_new", { new_id: this.id }).then((result) => {
        if (!result) {
          this.$router.push("/");
          return;
        }
        this.new_item = result;
      });
    },
    func_update_active_page(value) {
      if (this.$router.currentRoute.path !== "/") {
        this.$router.push({
          name: "main",
          params: {
            view_id: value,
          },
        });
      }
    },
  },
  mounted() {
    if (this.$route.params.id) this.func_get_data();
  },
};
</script>

<style></style>
