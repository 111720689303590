<template>
  <div class="shops__wrapper">
    <Header />
    <HeaderInfo />
    <Menu @active_page="func_update_active_page" :active_page="active_page" />
    <div class="shops__content">
      <h2>Наши магазины</h2>
      <div class="shops__body">
        <Progress :show="load" />
        <div class="shop__citys" v-if="!load && shops.length > 0">
          <div
            class="shop__city"
            v-for="item in shops"
            :key="item.city_shop_id"
          >
            <h3 v-if="item.shops.length">{{ item.city }}</h3>
            <div class="shops__flex" v-if="item.shops.length">
              <ShopItem
                v-for="obj in item.shops"
                :key="obj.shop_id"
                :obj="obj"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import HeaderInfo from "@/components/Header_info.vue";
import Menu from "@/components/Menu.vue";
import Progress from "@/components/Progress.vue";
import ShopItem from "@/components/Shops/ShopItem.vue";
export default {
  components: {
    Header,
    HeaderInfo,
    Menu,
    Progress,
    ShopItem,
  },
  data: () => ({
    shops: [],
    load: true,
    active_page: 1,
  }),
  methods: {
    func_get_data() {
      window.sendRequest("/get_shops", {}).then((result) => {
        if (!result) return;
        this.shops = result;
        this.load = false;
      });
    },
    func_update_active_page(value) {
      if (this.$router.currentRoute.path !== "/") {
        this.$router.push({
          name: "main",
          params: {
            view_id: value,
          },
        });
      }
    },
  },
  mounted() {
    this.func_get_data();
    this.active_page = 0;
  },
};
</script>

<style></style>
