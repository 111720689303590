<template>
  <div class="shop__item">
    <div class="shop__img">
      <img v-img :src="'/images/shops/' + obj.img" alt="" />
    </div>
    <div class="shops__images" v-if="obj.images">
      <img
        v-for="item in obj.images"
        :key="item.id"
        :src="'/images/shops/' + item.img"
        v-img
      />
    </div>
    <div class="shop__title">{{ obj.name }}</div>
    <div class="shop__adress">{{ obj.adress }}</div>
    <div class="shop__operation_mode">{{ obj.operation_mode }}</div>
    <div class="shop__link_map">
      <a :href="obj.link_map"
        ><i class="fa fa-map-marker"></i>Показать на карте</a
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    obj: Object,
  },
};
</script>

<style></style>
