<template>
  <div class="instruct__wrapper">
    <h4 class="title">Инструкции</h4>
    <div class="instruction__images">
      <img v-img src="https://bpcard.ru/img/изображение_viber_2021-12-22_10-42-58-596.jpg" />
      <img v-img src="https://bpcard.ru/img/изображение_viber_2021-12-22_10-42-57-967.jpg" />
      <img v-img src="https://bpcard.ru/img/изображение_viber_2021-12-22_10-42-56-290.jpg" />
      <img v-img src="https://bpcard.ru/img/изображение_viber_2021-12-22_10-42-57-248.jpg" />
    </div>
    <div class="videos">
      <iframe src="https://vk.com/video_ext.php?oid=-218601743&id=456239023&hd=2" width="100%" height="406"
        allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;" frameborder="0"
        allowfullscreen></iframe>
      <iframe src="https://vk.com/video_ext.php?oid=-218601743&id=456239024&hd=2" width="100%" height="406"
        allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;" frameborder="0"
        allowfullscreen></iframe>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgs: [],
    };
  },
};
</script>

<style></style>
