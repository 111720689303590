<template>
  <div class="header__main">
    <div class="header__container">
      <div class="auth_buttons" v-if="AUTH_STATE">
        <button class="auth" @click="func_show_user">
          {{ USER_STATE.login }}
        </button>
        <button class="auth_exit" @click="func_exit_user">Выход</button>
      </div>
      <div class="auth_buttons" v-else>
        <button class="auth" @click="func_show_login">Войти</button>
      </div>
      <div class="mobile-google">
        <a href="https://play.google.com/store/apps/details?id=com.roy126.bpcard">
          <img src="@/assets/images/pngwing.com.png" />
        </a>
      </div>

      <div class="search__panel">
        <div class="form__group">
          <transition name="search__anim">
            <input
              type="text"
              v-model="search.input"
              placeholder="Найти..."
              v-show="search.status"
            />
          </transition>
          <button class="search__icon" @click="this.func_search">
            <i class="fa fa-search"></i>
          </button>
        </div>
        <div class="notify__block">
          <button
            class="notify_icon"
            v-if="AUTH_STATE"
            @click="notify.show = !notify.show"
          >
            <i
              class="fa fa-bell anim_bell"
              aria-hidden="true"
              v-if="NOTICE_LIST_NO_SHOW_STATE > 0"
            ></i>
            <i
              class="fa fa-bell-o"
              aria-hidden="true"
              v-if="NOTICE_LIST_NO_SHOW_STATE === 0"
            ></i>
          </button>
          <div class="popup__notify" v-if="notify.show && NOTICE_LIST_STATE.length > 0">
            <ul>
              <li
                v-for="(item, index) in NOTICE_LIST_STATE"
                :key="index"
                @click="func_show_notice(item, index)"
                @mouseover="func_show_notice(item, index)"
              >
                <div class="content">{{ item.content }}</div>
                <div class="circle" v-if="!!!Number(item.show_notice)"></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search: {
        input: "",
        status: false,
      },
      notify: {
        show: false,
      },
    };
  },
  methods: {
    func_show_notice(obj, index) {
      if (Number(obj.show_notice) === 0) {
        window
          .sendRequestAuth("/view_notice", {
            notification_id: obj.notification_id,
          })
          .then(() => {
            let notice_list = this.NOTICE_LIST_STATE;
            obj.show_notice = "1";
            notice_list[index] = obj;
            this.$store.commit("CHANGE_NOTICE_LIST", notice_list);
          });
      }
    },
    func_search() {
      if (this.search.status) {
        this.search.status = false;
      } else {
        this.search.status = true;
      }
    },
    func_exit_user() {
      localStorage.removeItem("api_key");
      this.$store.commit("CHANGE_USER", null);
      this.$store.commit("CHANGE_AUTH", false);
      if (window.location.pathname !== "/") this.$router.push("/");
    },
    func_show_login() {
      this.$store.commit("CHANGE_MODAL");
      this.$store.commit("CHANGE_MODAL_ID", 1);
    },
    func_show_user() {
      if (this.$router.currentRoute.path !== "/user") this.$router.push("/user");
    },
  },
  computed: {
    ...mapGetters(["AUTH_STATE"]),
    ...mapGetters(["USER_STATE"]),
    ...mapGetters(["NOTICE_LIST_NO_SHOW_STATE"]),
    ...mapGetters(["NOTICE_LIST_STATE"]),
  },
};
</script>

<style>
.search__anim-enter {
  opacity: 0;
  transform: translateY(-100%);
}
.search__anim-enter-active {
  transition: all 0.3s ease;
}
.search__anim-enter-to {
  opacity: 1;
  transform: translateY(0%);
}

.search__anim-leave {
  opacity: 1;
  transform: translateY(0%);
}
.search__anim-leave-active {
  transition: all 0.3s ease;
}

.search__anim-leave-to {
  opacity: 0;
  transform: translateY(-100%);
}
.mobile-google {
}
.mobile-google img {
  width: 130px;
}
</style>
