<template>
  <div class="footer__wrapper">
    <div class="footer__container">
      <div class="left__item">© Большая перемена</div>
      <div class="center__item">г. Балаково ул. Московская д. 79</div>
      <div class="right__item">
        <div class="mobile-google">
          <a href="https://play.google.com/store/apps/details?id=com.roy126.bpcard">
            <img src="@/assets/images/pngwing.com.png" />
          </a>
        </div>
        <ul>
          <li>
            <a href="https://clck.ru/KQboi"><i class="fa fa-whatsapp"></i></a>
          </li>
          <li>
            <a href="https://vk.com/bpcardru"><i class="fa fa-vk"></i></a>
          </li>
          <li>
            <a href="https://ok.ru/bpcardru"><i class="fa fa-odnoklassniki"></i></a>
          </li>
          <li>
            <a href="https://www.youtube.com/channel/UCvjzndawIb5Fin-ml8w2U3w"
              ><i class="fa fa-youtube"></i
            ></a>
          </li>
          <li>
            <a href="https://t.me/bpcardru"
              ><i class="fa fa-telegram" style="color: #2ebce7"></i
            ></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
