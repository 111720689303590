<template>
  <div class="contacts__wrapper">
    <h4 class="title">Контакты</h4>
    <div class="contact__content">
      <h5>ООО "Большая перемена"</h5>
      <div class="contact">
        <ul>
          <li>г. Балаково ул. Московская д. 79</li>
          <li>Приемная тел: 8(8453)23-23-12</li>
          <li>email: bpkshp@mail.ru</li>
          <li>Тех. поддержка: +7 (929) 777-88-11</li>
          <li>email: bptex@mail.ru</li>
        </ul>
      </div>
      <a
        href="https://bpcard.ru/file/%D0%94%D0%BE%D0%B3%D0%BE%D0%B2%D0%BE%D1%80%20%D1%81%20%D1%80%D0%BE%D0%B4%D0%B8%D1%82%D0%B5%D0%BB%D1%8F%D0%BC%D0%B8.docx"
        class="dogovor"
        >Договор на организацию питания за родительскую плату</a
      >
      <div class="info">
        <ul>
          <li>Общество с ограниченной ответственностью «Большая Перемена»</li>
          <li>ИНН 6439096540 КПП 643901001</li>
          <li>ОГРН 1196451015485</li>
          <li>
            <span>Юридический адрес:</span>
            413850, Саратовская область, город Балаково, улица Московская, дом
            79
          </li>
          <li>
            <span>Фактический адрес:</span>
            413850, Саратовская область, город Балаково, улица Московская, дом
            79
          </li>
        </ul>
      </div>
      <div class="bank">
        <h5>Банковские реквизиты:</h5>
        <ul>
          <li>Расчетный счет: 40702810956000014831</li>
          <li>ПОВОЛЖСКИЙ БАНК ПАО СБЕРБАНК</li>
          <li>БИК 043601607</li>
          <li>к/с 30101810200000000607</li>
        </ul>
      </div>
      <div class="rabota">
        <h5>Режим работы</h5>
        <div class="rabota__content">
          <div class="rabota__item">
            <span>Понедельник - четверг</span>
            <span>8:00 - 17:00</span>
          </div>
          <div class="rabota__item">
            <span>Пятница</span>
            <span>8:00 - 16:00</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
