<template>
  <div class="progress__wrapper" v-show="show">
    <img src="https://bpcard.ru/images/2.gif" alt="" />
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
  },
};
</script>

<style></style>
