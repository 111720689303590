import Vue from 'vue'
import Router from 'vue-router'

import MainPage from '@/components/pages/Main.vue'
import Payment from '@/components/pages/Payment.vue'
import User from '@/components/pages/User.vue'
import Functions from '@/components/pages/Function.vue'
import PayInfo from '@/components/pages/PayInfo.vue'
import NewItem from '@/components/pages/New.vue';
import Shops from '@/components/pages/Shops.vue';

Vue.use(Router);

let router = new Router({
  mode: 'history',
  routes: [{
    path: '/',
    component: MainPage,
    name: 'main'
  }, {
    path: '/payment',
    component: Payment
  },
  {
    path: '/user',
    component: User
  },
  {
    path: '/pay_info',
    component: PayInfo
  },
  {
    path: '/functions/:id',
    component: Functions,
    props: true
  },
  {
    path: '/new/:id',
    component: NewItem,
    props: true
  },
  {
    path: '/shops',
    component: Shops,
  },
  { path: "*", component: MainPage }]
})

export default router;